var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "m-container",
    [
      _c("page-title", {
        staticClass: "mt-5",
        attrs: {
          "previous-location": "",
          "page-title": !_vm.$language ? "My Orders" : "আমার অর্ডারগুলি",
        },
      }),
      _c(
        "lazy-load",
        { attrs: { item: !_vm.fetchingOrders } },
        [
          _vm.networkError
            ? _c("no-content-component", {
                attrs: { message: _vm.networkErrorNoContentMessage },
              })
            : _vm.orders?.length === 0
            ? _c("no-content-component", {
                attrs: { message: _vm.noContentMessage },
              })
            : _vm._l(_vm.orders, function (order) {
                return _c("order-card", {
                  key: order.id,
                  staticClass: "mb-3",
                  attrs: { order: order },
                  on: { verify: _vm.verify },
                })
              }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }