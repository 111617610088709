<template>
  <m-container>
    <page-title
      previous-location
      :page-title="!$language ? 'My Orders' : 'আমার অর্ডারগুলি'"
      class="mt-5"
    ></page-title>
    <lazy-load :item="!fetchingOrders">
      <no-content-component
        v-if="networkError"
        :message="networkErrorNoContentMessage"
      ></no-content-component>
      <no-content-component
        v-else-if="orders?.length === 0"
        :message="noContentMessage"
      ></no-content-component>
      <order-card
        v-else
        v-for="order in orders"
        :key="order.id"
        :order="order"
        class="mb-3"
        @verify="verify"
      ></order-card>
    </lazy-load>
  </m-container>
</template>

<script>
import OrderCard from "#ef/cart/components/OrderCard";
import NoContentComponent from "/global/components/NoContentComponent";

export default {
  name: "Orders",
  components: { OrderCard, NoContentComponent },
  data() {
    return {
      orders: [],
      fetchingOrders: true,
      networkError: false,
    };
  },
  computed: {
    cart() {
      return this.$store.state.cart.cart;
    },
    noContentMessage() {
      return !this.$language
        ? "You currently have no orders"
        : "বর্তমানে আপনার কোনো অর্ডার নেই";
    },
    networkErrorNoContentMessage(){
      return !this.$language
        ? "Your orders are currently not available"
        : "বর্তমানে আপনার অর্ডার সংক্রান্ত তথ্য এনে দেয়া সম্ভব হচ্ছে না";
    }
  },
  async created() {
    await this.fetchOrders();
  },
  methods: {
    async fetchOrders() {
      const isOnline = window.navigator.onLine;
      if (!isOnline) {
        this.$root.$emit("alert", [
          "Alert",
          "There might be an internet connection issue on your system",
        ]);
        this.fetchingOrders = false;
        this.networkError = true;
        return;
      }
      try {
        this.fetchingOrders = true;
        this.orders = await this.$store.dispatch("cart/getAllOrders");
      } catch (error) {
        this.$notify(error);
      } finally {
        this.fetchingOrders = false;
      }
    },
    async verify(transactionId, transactionTime) {
      const isOnline = window.navigator.onLine;
      if ( !isOnline ) {
        this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
        return;
      }
      try {
        const resp = await this.$store.dispatch("cart/verify", {
          transactionId,
          transactionTime,
        });
        if (resp.tr_status === "failed") {
          this.$root.$emit("alert", [undefined, resp.message]);
        } else {
          this.$root.$emit("alert", ["Info", "Verification successful"]);
        }
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.orders = await this.$store.dispatch("cart/getAllOrders");
      }
    },
  },
};
</script>

<style lang="scss">
.cart-list {
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.cart-package-count {
  font-family: Poppins, serif;
  font-weight: normal;
  color: $text;
  font-size: 16px;
}
.purchase-content {
  margin-top: 15px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  p {
    font-family: Poppins, serif;
    text-align: center;
    color: $text;
  }
}
</style>
