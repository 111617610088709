<template>
  <m-base-card>
    <template v-slot:title
      >{{ !$language ? "Order ID" : "অর্ডার আইডি" }}: {{ order.id }}</template
    >
    <template v-if="canPay" v-slot:card-actions>
      <div>{{ expireFormatted }}</div>
    </template>
    <template v-slot:col-1>
      <div class="d-flex justify-center">
        <span class="mr-1">{{ !$language ? "STATUS" : "স্ট্যাটাস" }}: </span>
        <span>{{
          order.order_status === "succeeded" ? "Complete" : "Incomplete"
        }}</span>
      </div>
    </template>
    <template v-slot:col-2>
      <v-container>
        <v-row>
          <v-col>
            <m-base-card-info :label="!$language ? 'Amount' : 'পরিমাণ'"
              >{{ order.amount }}
              {{ order.currency.toUpperCase() }}</m-base-card-info
            >
          </v-col>
          <v-col>
            <m-base-card-info
              :label="!$language ? 'Last Transaction' : 'সর্বশেষ লেনদেন'"
              >{{
                order.last_transaction_id || "No Transaction Made"
              }}</m-base-card-info
            >
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:col-3>
      <div class="d-flex flex-column justify-center align-center">
        <m-base-card-button
          :disabled="!canPay"
          class="mb-2"
          @click="$router.push('/students/checkout/' + order.id)"
          >{{ !$language ? "Pay Now" : "এখন পে করুন" }}</m-base-card-button
        >
        <m-base-card-button
          :disabled="!canVerify"
          @click="
            $emit(
              'verify',
              order.last_transaction_id,
              order.last_transaction_time,
            )
          "
          >{{
            !$language ? "Verify Last Transaction" : "সর্বশেষ লেনদেন যাচাই করুন"
          }}</m-base-card-button
        >
      </div>
    </template>
  </m-base-card>
</template>

<script>
import moment from "moment";
import MBaseCard from "~ef/components/MBaseCard";
import MBaseCardButton from "~ef/components/MBaseCardButton";
import MBaseCardInfo from "~ef/components/MBaseCardInfo";
import { getCurrentTimeUTC } from "/global/utils/helpers";
export default {
  name: "OrderCard",
  components: { MBaseCardButton, MBaseCardInfo, MBaseCard },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      removing: false,
      remainingTimeToPay: -1,
    };
  },
  computed: {
    canPay() {
      return (
        this.remainingTimeToPay > 0 && this.order.order_status !== "succeeded"
      );
    },
    canVerify() {
      return (
        this.order.last_transaction_id &&
        this.order.order_status !== "succeeded"
      );
    },
    expireFormatted() {
      if (this.remainingTimeToPay > 0) {
        return `Expires in ${moment
          .duration(this.remainingTimeToPay, "seconds")
          .humanize()}`;
      }
      return "Already Expired";
    },
  },
  watch: {
    remainingTimeToPay(value) {
      if (value < 0 && this.intervalId) {
        clearInterval(this.intervalId);
      }
    },
  },
  created() {
    const remaining = this.getRemainingTime();
    if (remaining > 0) {
      this.remainingTimeToPay = remaining;
      this.intervalId = setInterval(
        () => (this.remainingTimeToPay = this.getRemainingTime()),
        1000,
      );
    }
  },
  methods: {
    getRemainingTime() {
      const _now = Math.ceil(getCurrentTimeUTC() / 1000);
      return this.order.expires_in - (_now - this.order.created_at);
    },
    async removeItem() {
      try {
        this.removing = true;
        await this.$store.dispatch("cart/removeItem", {
          id: this.batch.batch_id,
        });
      } catch (e) {
        this.$displayError(e);
        this.$logger.log(e);
      } finally {
        this.removing = false;
      }
    },
  },
};
</script>
