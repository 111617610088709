var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("m-base-card", {
    scopedSlots: _vm._u(
      [
        {
          key: "title",
          fn: function () {
            return [
              _vm._v(
                _vm._s(!_vm.$language ? "Order ID" : "অর্ডার আইডি") +
                  ": " +
                  _vm._s(_vm.order.id)
              ),
            ]
          },
          proxy: true,
        },
        _vm.canPay
          ? {
              key: "card-actions",
              fn: function () {
                return [_c("div", [_vm._v(_vm._s(_vm.expireFormatted))])]
              },
              proxy: true,
            }
          : null,
        {
          key: "col-1",
          fn: function () {
            return [
              _c("div", { staticClass: "d-flex justify-center" }, [
                _c("span", { staticClass: "mr-1" }, [
                  _vm._v(
                    _vm._s(!_vm.$language ? "STATUS" : "স্ট্যাটাস") + ": "
                  ),
                ]),
                _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.order.order_status === "succeeded"
                        ? "Complete"
                        : "Incomplete"
                    )
                  ),
                ]),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: "col-2",
          fn: function () {
            return [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "m-base-card-info",
                            {
                              attrs: {
                                label: !_vm.$language ? "Amount" : "পরিমাণ",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.order.amount) +
                                  " " +
                                  _vm._s(_vm.order.currency.toUpperCase())
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c(
                            "m-base-card-info",
                            {
                              attrs: {
                                label: !_vm.$language
                                  ? "Last Transaction"
                                  : "সর্বশেষ লেনদেন",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.order.last_transaction_id ||
                                    "No Transaction Made"
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "col-3",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass: "d-flex flex-column justify-center align-center",
                },
                [
                  _c(
                    "m-base-card-button",
                    {
                      staticClass: "mb-2",
                      attrs: { disabled: !_vm.canPay },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push(
                            "/students/checkout/" + _vm.order.id
                          )
                        },
                      },
                    },
                    [_vm._v(_vm._s(!_vm.$language ? "Pay Now" : "এখন পে করুন"))]
                  ),
                  _c(
                    "m-base-card-button",
                    {
                      attrs: { disabled: !_vm.canVerify },
                      on: {
                        click: function ($event) {
                          return _vm.$emit(
                            "verify",
                            _vm.order.last_transaction_id,
                            _vm.order.last_transaction_time
                          )
                        },
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          !_vm.$language
                            ? "Verify Last Transaction"
                            : "সর্বশেষ লেনদেন যাচাই করুন"
                        )
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }